import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const YandexMetrika = () => {
    const location = useLocation();

    // Задаём массив путей, на которых нужно подключать Метрику
    const allowedPaths = [
        '/main',
        '/extended-warranty-list',
        '/my-orders',
        '/my-cars',
        "/my-orders-single",
        "/registrations-for-services",
        "/registrations-for-service-single",
        "/wheel-storage-list",
        "/wheel-storage-single",
        "/my-purchases-list",
        "/my-purchases-single",
        "/my-discount",
        "/my-profile",
        "/extended-warranty-activate-form",
        "/settings",
        "/error-page"
    ];

    useEffect(() => {

        // Если текущий путь входит в список разрешённых:
        if (allowedPaths.includes(location.pathname)) {

            // Если скрипт ещё не загружен (проверяем наличие window.ym)
            if (!window.ym) {
                (function (m, e, t, r, i, k, a) {
                    m[i] = m[i] || function () {
                        (m[i].a = m[i].a || []).push(arguments);
                    };
                    m[i].l = 1 * new Date();
                    k = e.createElement(t);
                    a = e.getElementsByTagName(t)[0];
                    k.async = 1;
                    k.src = r;
                    a.parentNode.insertBefore(k, a);
                })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

                window.ym(99742827, 'init', {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                });
            }
        }
    }, [location.pathname, allowedPaths]);

    return null; // Компонент ничего не рендерит
};

export default YandexMetrika;
